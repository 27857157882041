import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';
import logo_img from '../imgs/IT-cervices-64-bit-information-technology.jpg';

const Navbar = () => {
    
    const [showMenu, setShowMenu] = useState(false); 
    
    const closeMenu = () => {
        setShowMenu(false);
    }
    
    const handleNavigation = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }


    return (
        <nav>
            <div className="logo">
                <img src={logo_img} alt="Logo" />
            </div>
            <div className={`link__container__all ${showMenu ? 'link__container__all__show__menu' : ''}`}>
            <ul>
                    <li><Link to="/" onClick={() => { handleNavigation(); closeMenu(); }}>HOME</Link></li>
                    <li><Link to="/about" onClick={() => { handleNavigation(); closeMenu(); }}>ABOUT</Link></li>
                    <li><Link to="/services" onClick={() => { handleNavigation(); closeMenu(); }}>SERVICES</Link></li>
                    <li><Link to="/contact" onClick={() => { handleNavigation(); closeMenu(); }}>CONTACT</Link></li>
                </ul>
                <a ><Link to="/contact" className="ttu__btn" onClick={() => { handleNavigation(); closeMenu(); }}>Talk To Us</Link></a>
            </div>
            <div className={`hamburger__menu ${showMenu ? 'menuToggleColor' : ''}`} onClick={() => setShowMenu(!showMenu)}>&#9776;</div>
        </nav>
    )
}

export default Navbar;
