import React from 'react';
import './map.css';

const Map = () => {
  return (
    <div>
        <section id="google-maps-section">
          <div className="google-maps-section">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d451139.20487980795!2d-82.29804341857067!3d27.947202405277874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1698291167737!5m2!1sen!2sus" width="100%" height="100%" style={{border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </section>
    </div>
  )
}

export default Map;