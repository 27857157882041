import React from 'react';
import './servicesComponentsTres.css';
import smartDevice from '../../imgs/smart-google.jpg';

const ServicesComponentsTres = () => {
  return (
    <div>
        <div className='it__solutions__container__3'>
            <div className='it__solutions__item__3'>
                <h2>Seamless Smart Device Setup by 64-Bit Technology: Simplify Your Life</h2>
                <p>Embrace the convenience of smart devices without the hassle of setup. Trust 64-Bit Technology's expert IT service technicians to effortlessly install a variety of smart devices, including light bulbs, doorbells, thermostats, security systems, and more. With our satisfaction guarantee, your peace of mind is assured—or your money back!</p>
                <a href="#/contact">Conctact Us</a>
            </div>
            <div className='it__solutions__img__3 '><img src={smartDevice} alt="woman-working-computer-lab" /></div>
        </div>
    </div>
  )
}

export default ServicesComponentsTres