import React from 'react';
import Map from '../../src/components/contact__components/Map';
import ContactLanding from '../components/contact__components/ContactLanding';
import ContactForm from '../components/contact__components/ContactForm';
import './contact.css';

const Contact = () => {
  return (
    <div>
        <ContactLanding />
        <ContactForm />
        <div className='contact__coverage'>
            <h2>We Cover All Central Florida Area and More!</h2>
            <p>
                "We provide comprehensive coverage throughout Central Florida for all in-person tasks. However, for services that do not necessitate physical presence, such as website development, we extend our offerings to any location. Our website development services are complemented by virtual meetings for seamless collaboration."</p>
            <a href="tel:8137040221">Call Now</a>    
        </div>
        <Map />
    </div>
  )
}

export default Contact;