import React from 'react';
import './services.css';
import Services__components__uno from '../components/services__components/Services__components__uno';
import Services__components__dos from '../components/services__components/Services__components__dos';
import ServicesComponentsTres from '../components/services__components/ServicesComponentsTres';
import ServicesLastContainer from '../components/services__components/ServicesLastContainer';

const Services = () => {
  return (
    <div>
        <div className='services__landing__container'>
            <div>

            <h1>Unlocking Your Tech Potential: <br /> Expert Services Await <br /> 64 Bit Technology</h1>
            <p>At 64-Bit, we offer comprehensive IT services tailored for small businesses, law firms, schools, CPA firms, and manufacturing companies. Our solutions are designed to meet the specific needs of your business, whether you're looking for IT services near you, cost-effective options, or specialized support for your industry. From robust web development to reliable IT support, our team ensures your business operations are efficient and secure.</p>
            <a href="#/contact">Learn More</a>
            </div>
        </div>
        <div>
          <Services__components__uno />
          <Services__components__dos />
          <ServicesComponentsTres />
          <ServicesLastContainer />
        </div>

    </div>
  )
}

export default Services;