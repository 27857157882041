import React from 'react';
import './servicesLastContainer.css';

const ServicesLastContainer = () => {
  return (
    <div className=''>
        <div className='services__last__container__div__1'>
            <h2>"Transforming Technical Challenges into Seamless Solutions: Your Trusted IT Partner"</h2>
            <p>Elevate your technology experience with 64 Bit Technology, where expertise meets innovation. With a wealth of experience spanning various roles, including Field Technician Break/Fix and Help Desk Technician, we bring unparalleled proficiency in hardware repairs, software installations, network diagnostics, and end-user support. Our commitment to excellence is evident in our meticulous approach to maintaining accurate records, conducting preventative maintenance, and providing on-site technical assistance to ensure optimal client satisfaction.</p>
        </div>
        <div className='services__last__container__div__2'>
            <div>
                <h3>"Cultivating Excellence: Professional Experiences and Education"</h3>
                <p>In addition to our hands-on experience, our journey includes a passion for web development, showcased through our freelance work as a Web Developer. Leveraging cutting-edge technologies such as HTML, CSS, JavaScript, and Bootstrap, we have crafted three stunning static websites that seamlessly blend form and function. Our collaborative approach ensures that each project is tailored to meet your unique requirements, while our expertise in search engine optimization (SEO) guarantees maximum visibility and impact.</p>
            </div>
            <div>
                <h3>"Empowering Success: Services and Expertise"</h3>
                <p>Backed by a Certificate in Information Technology from Aparicio-Levy Technical College and self-taught proficiency in HTML5, CSS, JavaScript, and Python, we are equipped with the knowledge and skills to tackle even the most complex IT challenges. Partner with us to unlock the full potential of your technology landscape and drive your business towards success.</p>
            </div>
        </div>
    </div>
  )
}

export default ServicesLastContainer