import React from 'react';
import './about.css';

const About = () => {
  return (
    <div>
        <div className='about__landing__img__container'></div>
        <div className='about__desc__container'>
            <div className='about__desc__item__1'>
                <h1>About 64-bit-technology</h1>
                <p>At 64-Bit-Technology, we are dedicated to providing top-notch IT services to businesses and individuals alike. With a passion for technology and a commitment to excellence, we strive to be your trusted partner in all things IT-related.</p>
                <a href="tel:8137040221">Call Now</a>
            </div>

            <div className='about__desc__item__2'>
                <h2>Why Choose Us?</h2>
                <p>✔️<strong>Expertise:</strong>  With years of experience in the industry, we have the knowledge and skills to tackle any IT challenge that comes our way. From computer repairs to hardware upgrades, we've got you covered.</p>
                <p>✔️<strong>Reliability:</strong>   When you choose 64-Bit-Technology, you can count on us to deliver reliable and efficient service every time. We understand the importance of keeping your technology up and running, and we'll work tirelessly to ensure minimal downtime.</p>
                <p>✔️<strong>Personalized Service:</strong>  As a solo IT specialist, we offer personalized service tailored to your specific needs. We take the time to understand your unique requirements and provide customized solutions that exceed your expectations.</p>
                <p>✔️<strong>Commitment to Security:</strong>  Your data's security is our top priority. We implement robust security measures to safeguard your information and protect you from potential cyber threats.</p>
            </div>
        </div>

        <div className='about__desc__container__last'>
            <div className='about__desc__container__last__item last__item__1'>
                <h2>Our Mission: Empowering Your Technology Solutions</h2>
                <p>At 64-Bit Technology, we are dedicated to empowering businesses and individuals through reliable and efficient IT solutions in Tampa, FL. Our mission is to provide cost-effective technology services that streamline operations and enhance productivity. Whether you're a small business seeking operational efficiency with tailored Tampa IT solutions or an individual in need of expert tech support, we're here to help you harness the power of technology to achieve your goals. Trust us for exceptional IT services and technology solutions.</p>
            </div>
            <div className='about__desc__container__last__item last__item__2'>
                <h2>Client-Centric Approach: Your Satisfaction Guaranteed</h2>
                <p>At 64-Bit Technology, client satisfaction is our top priority. We take a client-centric approach to every IT project, working closely with you to understand your unique needs and deliver tailored Tampa IT solutions that exceed your expectations. With a commitment to personalized service and attention to detail, we ensure that your technology requirements are met with precision and efficiency. Trust us for exceptional IT services and technology solutions in Tampa, FL.</p>
            </div>
            <div className='about__desc__container__last__item last__item__3'>
                <h2>Continuous Learning: Staying Ahead in Technology Trends</h2>
                <p>In the dynamic landscape of technology, staying ahead is crucial. At 64 Bit Technology, we prioritize continuous learning and professional development to remain at the forefront of industry trends. Our team stays updated with the latest tools, techniques, and cybersecurity measures to deliver cutting-edge solutions. With our proactive approach, you can trust us to tackle any IT challenge with expertise and innovation.</p>
            </div>

        </div>
    </div>
  )
}

export default About