import React from 'react';
import './contactLanding.css';

const ContactLanding = () => {
  return (
    <div>
        <div className='contact__landing__div__1'>
            <h1>"Connect with Us: Let's Build Your Future Together"</h1>
            <p>"Get in touch with 64-Bit for expert IT services and simple, cost-efficient web development. We cater to small businesses, law firms, schools, CPA firms, and manufacturing companies. Whether you need affordable IT solutions, local support, or specialized services for your industry, our team is here to help. Contact us today to ensure your business's IT and web development needs are met with precision and excellence."</p>
            <a href="tel:8137040221">Call Us Now!</a>
        </div>
    </div>
  )
}

export default ContactLanding