import React from 'react';
import './contactForm.css';

const ContactForm = () => {
  return (
    <div>
        <div className='contact__form__container'>
                <h2>Tell Us About Your Project!</h2>
            <div className='input__container__all'>
                    <form action="https://formsubmit.co/hansel.tti@gmail.com" method="POST">
                        <div className='input__container__all__first__div'>
                            <input type="text" placeholder='Full Name' required/>
                            <input type="email" placeholder='Your Email' required />
                            <input type="number" placeholder='Your Phone Number' required/> 
                            <textarea placeholder='Enter Your Text Here' name="" id="" cols="30" rows="10" required></textarea> 
                            <button type='submit'>Send</button>
                        </div>
                    </form>
                <div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContactForm;