import React from 'react';
import './services__components__dos.css';
import webdeveloment from '../../imgs/website-good-design.jpg'

const Services__components__dos = () => {
  return (
    <div>
        <div className='it__solutions__container__2'>
            <div className='it__solutions__img__2 '><img src={webdeveloment} alt="woman-working-computer-lab" /></div>
            <div className='it__solutions__item__2'>
                <h2>Elevate Your Online Presence with Elegant Web Development Solutions</h2>
                <p>Experience the fusion of simplicity and sophistication with our website development expertise. Beyond traditional IT services, we craft exquisite web pages from the ground up using HTML, CSS, JavaScript, and Bootstrap. Currently specializing in static web projects, including soon-to-launch ecommerce websites. Curious? Let us construct your dream website for FREE! Test it out, pay only if satisfied, and keep it online with ease.</p>
                <a href="#/contact">Conctact Us</a>
            </div>
        </div>
    </div>
  )
}

export default Services__components__dos