import React from 'react';
import './services__components__uno.css';
import infotech from '../../imgs/info-tech-.jpg'

const Services__components__uno = () => {
  return (
    <div>
          <div className='it__solutions__container'>
            <div className='it__solutions__item'>
                <h2>Expert IT Solutions: Your Partner in Technology</h2>
                <p>Experience seamless support and solutions tailored to your needs. Whether you're facing hardware or software challenges, we're here to assist remotely or on-site. From quick fixes to complex projects, we've got you covered. Need to set up new equipment or relocate your office? Trust us to handle it efficiently. Let's simplify your technology together!</p>
                <a href="#/contact">Conctact Us</a>
            </div>
            <div className='it__solutions__img '><img src={infotech} alt="people working on a computer" /></div>
        </div>
    </div>
  )
}

export default Services__components__uno;