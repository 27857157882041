import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';
import myLogo from '../imgs/IT-cervices-64-bit-information-technology.jpg'

const Footer = () => {
  const handleNavigation = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page with smooth animation
  };
  return (
    <footer className='footer'>
      <div className='footer__all__items__container'>
        <div className='footer__contact__info'>
          <logo className='footer__logo'><img src={myLogo} alt="Logo" /><p>64 Bit Technology</p></logo>
          <p>
            Phone: <br />
            <a href="tel:8137040221">(813) 704 0221</a> 
          </p>
          <a className='' ><Link to="/contact" onClick={handleNavigation} className='contact-btn footer__btns__1'>Contact Us</Link></a>
          <a className='footer__btns__2' href="https://buy.stripe.com/fZecQj8nc8oB8ZG7st" target="_blank">Donate</a>
          <a className='footer__btns__2 footer__btns__2__coffe' href="https://donate.stripe.com/00g3fJ32S34h4JqdQQ" target="_blank">Support</a>
        </div>
        <div className='footer__links'>
          <Link to="/" onClick={handleNavigation}>Home</Link>
          <Link to="/about" onClick={handleNavigation}>About</Link>
          <Link to="/services" onClick={handleNavigation}>Services</Link>
          <Link to="/contact" onClick={handleNavigation}>Contact Us</Link>
        </div>
        <div></div>
      </div>

      <div className='footer__date__priv'>
        <p>At 64-bit Technology, we specialize in delivering top-notch IT services and web development solutions tailored for small businesses, law firms, schools, CPA firms, and manufacturing companies in Tampa, FL. Our expert team ensures that your IT needs are met with precision, whether you require Tampa IT solutions, cost-effective IT support, or comprehensive Tampa network troubleshooting. We also cater to home IT needs, providing exceptional IT services for home and comprehensive technology support wherever required. Trust us to deliver outstanding Tampa tech solutions that keep your operations running smoothly. We extend our IT services to all of Central Florida.</p> <br />
        <p>Design by:<a href="https://64bittechnology.com" target='blank'> 64bit-technology</a> </p> 
      </div>
        <details>
            <summary>Privacy Policy</summary>
            <p>64Bit-Technology is committed to safeguarding your privacy and protecting your personal information. We collect and process your data in compliance with applicable data protection laws and regulations. The information we collect may include personal and contact details, device information, and usage data, all of which are used solely for the purpose of providing and improving our IT services. We do not share your data with third parties unless required by law or to fulfill the services you've requested. You have the right to access, correct, or delete your personal data, and we maintain strict security measures to protect your information from unauthorized access or disclosure. By using our services, you consent to our privacy policy and acknowledge your responsibility to review it periodically for updates. If you have any concerns or requests regarding your personal data, please contact us. <a class="text-warning text-decoration-none" href="tel:8137040221">(813) 704 0221</a></p>
        </details>
    </footer>  
  )
}

export default Footer;