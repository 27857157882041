import React from 'react';
import './home.css';
import profile__1 from '../imgs/profile picture woman glasses.jpg';
import profile__2 from '../imgs/profile picture woman photographer.jpg';
import profile__3 from '../imgs/selfie picture man.jpg';
import green__rj45 from '../imgs/green-rj45-ethernet-cable.jpg';
import old__ram from '../imgs/old-ram-computer-hardware.jpg';
import networking from '../imgs/networking-data-installation.jpg';


const Home = () => {
  return (
    <div>
        <div className='landing__img'></div>
        <div className='landing__img__desc'>
            <h1>professional Technology assistance</h1>
            <p>we make technology accessible!</p>
            <a href="#/contact">contact us</a>
        </div>
        <section className="home__container">
          <h1>64-bit-technology</h1>
          <p>
          Welcome to 64-Bit Technology, your trusted partner for expert IT services and cost-efficient website development in Tampa, FL. We specialize in serving small businesses, law firms, schools, and manufacturing companies with reliable IT solutions and affordable web development. Whether you need specialized IT support tailored to your industry or comprehensive IT services for business, we are dedicated to helping your business thrive. Trust 64-Bit Technology to deliver exceptional Tampa IT solutions and ensure your operations run smoothly and efficiently.
          </p>
          
          <h2>Our Expertise</h2>
          <p>
          At 64-bit Technology, we specialize in a range of IT services, including break/fix solutions, computer repairs, hardware upgrades, system relocations, and software installations in Tampa, FL. Our experienced team is dedicated to simplifying your technology experience with straightforward IT solutions and efficient Tampa computer repair services. Trust us to handle your IT challenges with precision and expertise.
          </p>
          
          <h2>Web Development</h2>
          <p>
          We excel in crafting elegant websites from scratch using HTML, CSS, JavaScript, and Bootstrap. Whether you're planning a static web project or future e-commerce ventures, our Tampa website development team is here to assist you. Take advantage of our special offer: get a free website build, and only pay if you're satisfied with the final result. Trust us for exceptional Tampa web development solutions tailored to your needs.
          </p>
          
          <h2>Smart Home Integration</h2>
          <p>
          In addition to IT solutions, we offer expert smart home integration services in Tampa, FL. Our IT service technicians can seamlessly install and configure smart light bulbs, video doorbells, thermostats, security systems, and water leak detectors. Make your home as smart as your business with our comprehensive smart home technology services and reliable IT support.
          </p>
          
        <h2>Why Choose Us</h2>
        <ul>
          <li><strong>Reliability:</strong> Count on us to be your dedicated IT partner, solving problems and creating solutions that work for you.</li>
          <li><strong>Affordability:</strong> Our competitive rates and free website trial make quality IT services accessible to everyone.</li>
          <li><strong>Simplicity:</strong> We believe in straightforward IT solutions that simplify your technology experience.</li>
        </ul>
        
        <h2>Contact Us</h2>
        <p>
          Ready to experience IT support that simplifies your tech life? Contact us today for all your IT needs. 64-bit-technology looks forward to making your technology journey smooth, affordable, and elegant.
        </p>
      </section>

      <div className='imgs__devider__container'>
        <div className='imgs__devider__item__div'><img src={green__rj45} alt="rj45-cable-ethernet" /></div>
        <div className='imgs__devider__item__div'><img src={old__ram} alt="rj45-cable-ethernet" /></div>
        <div className='imgs__devider__item__div'><img src={networking} alt="rj45-cable-ethernet" /></div>
      </div>

      {/* review section below */}
      <section>
        <h2 className='testimonial__h2'>Testimonials</h2>
      <div className="reviews">
          <div className="review">
            <img src={profile__1} alt='profile-picture'/>
            <span className="rating">&#9733;</span>
            <span className="rating">&#9733;</span>
            <span className="rating">&#9733;</span>
            <span className="rating">&#9733;</span>
            <span className="rating">&#9733;</span>
            <p className="mt-3">
              "Fantastic service! I highly recommend this company. The team is professional, and they delivered great results."
            </p>
            
          </div>
          <div className="review">
          <img src={profile__2} alt='profile-picture'/>
            <span className="rating">&#9733;</span>
            <span className="rating">&#9733;</span>
            <span className="rating">&#9733;</span>
            <span className="rating">&#9733;</span>
            <span className="rating">&#9734;</span>
            <p className="mt-3">
              "Good experience overall. Their expertise in web development is impressive. I had a minor issue, but they resolved it quickly."
            </p>
            
          </div>
          <div className="review">
          <img src={profile__3} alt='profile-picture' />
            <span className="rating">&#9733;</span>
            <span className="rating">&#9733;</span>
            <span className="rating">&#9733;</span>
            <span className="rating">&#9733;</span>
            <span className="rating">&#9733;</span>
            <p className="mt-3">
              "Great experience with their services. The quality of work is impressive!"
            </p>
            
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home