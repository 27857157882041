import React from "react";
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import './app.css';
import Navbar from "./navbar/Navbar";
import Home from "./home/Home";
import Footer from "./footer/Footer";
import About from "./about/About";
import Services from './services/Services';
import Contact from "./contact/Contact";


const App = () => {
    return (
        <Router> 
                <Navbar />
            <div>
                    <Routes>

                        <Route path="/" element={<Home/>} />
                        <Route path="/about" element={<About/>} />
                        <Route path="/services" element={<Services/>} />
                        <Route path="/contact" element={<Contact />} />
                    </Routes>
            </div>
            <Footer/>
        </Router>
    );
}

export default App;